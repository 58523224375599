/** @jsxImportSource @emotion/react */

import {Style} from "./style";

const Footer = () => {
    return(
        <div css={Style}>
            Created by Maulana Zulkifar
        </div>
    )
}

export default Footer;