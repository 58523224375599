import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ApolloClient} from "@apollo/client";
import { HttpLink} from "@apollo/client";
import { InMemoryCache} from "@apollo/client";
import { ApolloProvider} from "@apollo/client";

const client = new ApolloClient({
    link: new HttpLink({
        uri: `https://graphql-pokeapi.vercel.app/api/graphql`
    }),
    cache: new InMemoryCache()
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
